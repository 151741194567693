export const URLS = {
  user: {
    getCurrentUser: "/v1/user/current",
    loginGoogle: "/v1/user/login/social/google",
    logout: "/v1/user/logout",
    sendFeedback: "/v1/user/feedback/send",
    admin: {
      getUsers: "/v1/admin/user/list",
      updateUser: "/v1/admin/user/update",
      getPlayLogs: "/v1/admin/user/play_log",
    },
  },
  invitation: {
    accept: "/v1/invitation/accept",
    admin: {
      createInvitation: "/v1/admin/invitation/create",
      sendInvitations: "/v1/admin/invitation/send_batch",
      getInvitations: "/v1/admin/invitation/list",
      deleteInvitation: "/v1/admin/invitation/delete",
    },
  },
  chat: {
    getChatList: "/v1/admin/chat/list",
    getChatMessages: "/v1/admin/chat/messages",
  },
  game: {
    getInstance: "/v1/game/instance/get",
    admin: {
      getGameInstances: "/v1/admin/game_instance/list",
      getGameInstanceLog: "/v1/admin/game_instance/log",
      stopGameInstance: "/v1/admin/game_instance/stop",
    },
  },
  agent: {
    getAgentsByGroup: "/v1/agent/list",
    search: "/v1/agent/search",
    admin: {
      getInstanceById: "/v1/agent/instance/get",
      getAgentInstances: "/v1/admin/agent_instance/list",
      updateStatus: "/v1/admin/agent_instance/update",
      getAgents: "/v1/admin/agent/list",
      getAgent: "/v1/admin/agent/get",
      getAgentCheckpoints: "/v1/admin/checkpoint/list",
      summon: "/v1/admin/agent/summon",
      create: "/v1/admin/agent/create",
      update: "/v1/admin/agent/update",
      copy: "/v1/admin/agent/copy",
      archive: "/v1/admin/agent/archive",
      bulk: "/v1/admin/agent/bulk",
    },
  },
  environment: {
    getList: "/v1/environment/list",
  },
  keys: {
    getApiKeys: "/v1/keys/list",
    deleteApiKey: "/v1/keys/delete",
    createApiKey: "/v1/keys/create",
  },
};
export const WAITLIST_URL =
  "https://docs.google.com/forms/d/e/1FAIpQLSfl1ODEIHbKgVvbJo0NOWxE3NcfFm-7zKHpbVdMpRDr_05Mzg/viewform";
